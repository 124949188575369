import { useMemo } from 'react'

/**
 * this hook returns an object with offerings availability data
 * conposed in a certain way:
 * byDate - object that has all of the offerings' availability preferences sorted by date
 * used to check if the date should be blocked or not
 * *
 * byOrderItem - array with order item IDs to useFragment with
 */

export const useOfferingsAvailability = (orderItems) => {
  return useMemo(() => {
    return orderItems.reduce(
      (acc, { offering: { availabilityPreferences, slug } }) => {
        if (!availabilityPreferences?.preference) return acc

        const byDate = {}

        availabilityPreferences.preference.forEach((pref) => {
          if (byDate[pref]) byDate[pref] = [...byDate[pref], slug]
          else byDate[pref] = [slug]
        })

        acc = {
          byDate: { ...acc.byDate, ...byDate },
          byOrderItem: [...acc.byOrderItem, slug],
        }

        return acc
      },
      { byDate: {}, byOrderItem: [] },
    )
  }, [orderItems])
}
